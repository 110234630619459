import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/tmp/89a06d9/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2 {...{
      "id": "general-guidance"
    }}>{`General guidance`}</h2>
    <p><em parentName="p">{`Content switcher`}</em>{` manipulates the content shown following an exclusive or “either/or” pattern.
It is used to toggle between two or more content sections within the same space on screen. Only one section can be shown at a time.`}</p>
    <h4 {...{
      "id": "text"
    }}>{`Text`}</h4>
    <p>{`Be concise and specific. Titles have a max of two words.`}</p>
    <h4 {...{
      "id": "default-selection"
    }}>{`Default selection`}</h4>
    <p>{`Based on usage, there should be a default selection. The default selection is always the first option in a switcher.`}</p>
    <h2 {...{
      "id": "related-components"
    }}>{`Related components`}</h2>
    <h4 {...{
      "id": "content-switcher-vs-toggle"
    }}>{`Content switcher vs. toggle`}</h4>
    <p>{`The content switcher is used for large groups of content, as opposed to the `}<a parentName="p" {...{
        "href": "/components/toggle"
      }}>{`toggle`}</a>{` which is meant for a “yes/no” or “on/off” binary decision.`}</p>
    <span {...{
      "className": "gatsby-resp-image-wrapper",
      "style": {
        "position": "relative",
        "display": "block",
        "marginLeft": "auto",
        "marginRight": "auto",
        "maxWidth": "1152px"
      }
    }}>{`
      `}<span parentName="span" {...{
        "className": "gatsby-resp-image-background-image",
        "style": {
          "paddingBottom": "72.69021739130434%",
          "position": "relative",
          "bottom": "0px",
          "left": "0px",
          "backgroundSize": "cover",
          "display": "block"
        }
      }}></span>{`
  `}<img parentName="span" {...{
        "className": "gatsby-resp-image-image",
        "alt": "content switcher example",
        "title": "content switcher example",
        "src": "/static/988ed9f3e325920634f28eb6ddcf3310/fb070/content-switcher-usage-1.png",
        "srcSet": ["/static/988ed9f3e325920634f28eb6ddcf3310/d6747/content-switcher-usage-1.png 288w", "/static/988ed9f3e325920634f28eb6ddcf3310/09548/content-switcher-usage-1.png 576w", "/static/988ed9f3e325920634f28eb6ddcf3310/fb070/content-switcher-usage-1.png 1152w", "/static/988ed9f3e325920634f28eb6ddcf3310/c3e47/content-switcher-usage-1.png 1472w"],
        "sizes": "(max-width: 1152px) 100vw, 1152px"
      }}></img>{`
    `}</span>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      